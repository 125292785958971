.jvectormap-label {
    position: absolute;
    display: none;
    border: solid 1px #CDCDCD;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #292929;
    color: white;
    font-size: 10px!important;
    padding: 3px;
    z-index: 9999;
}

.jvectormap-zoomin, .jvectormap-zoomout {
    position: absolute;
    top: 100%;
    margin-top: -25px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #d2d6de;//rgba(0,0,0,0.4);
    padding: 5px;
    color: #444;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
    font-weight: bold;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.jvectormap-zoomin {
    left: 100%;
    margin-left: -50px;
}

.jvectormap-zoomout {
    left: 100%;
    margin-left: -30px;
}